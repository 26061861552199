import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "shards-ui/dist/css/shards.min.css"
import SearchFilters from './SearchFilters';
import NeighbourhoodsList from './NeighbourhoodsList';
import PropertyPage from './PropertyPage';
import NeighbourhoodPage from './NeighbourhoodPage';
import LandingPage from './LandingPage';
import FlatFinderNav from './FlatFinderNav';
import SavedProperties from './SavedProperties';
import FlatfinderFooter from './FlatfinderFooter';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
  useLocation
} from "react-router-dom";

function App() {
  return (
    <div className='FlatMaincontainer'>

      <Router>
        <NavBarWithParam/>
        <Switch>
          <Route path="/search">
            <ProfileParam />
          </Route>
          <Route path="/saved">
            <SavedProperties />
          </Route>
          <Route path="/neighbourhoods/:neighbourhoodId">
            <NeighbourHoodParam />
          </Route>
          <Route path="/properties/:propertyId">
            <PropertyParam />
          </Route>
          <Route path="/neighbourhoods">
            <NeighbourhoodsList />
          </Route>
          <Route path="/">
            <LandingPage />
          </Route>
        </Switch>
        </Router>
        <FlatfinderFooter />
    </div>
  );
}

function NeighbourHoodParam() {
  let { neighbourhoodId } = useParams();
  return (
    <NeighbourhoodPage neighbourhoodId={neighbourhoodId} />
  );
}

function PropertyParam() {
  let { propertyId } = useParams();
  return (
    <PropertyPage propertyId={propertyId} />
  );
}
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ProfileParam() {
  let query = useQuery();
  console.log(query.get("profileId"))

  return (
    <SearchFilters profileId={query.get('profileId')} />
  )
}

function NavBarWithParam() {
  return (
    <FlatFinderNav params={useQuery()} />
  )
}
export default App;
