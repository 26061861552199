import React from 'react';
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import './SearchProfileTrigger.css';

class SearchProfileTrigger extends React.Component {

    constructor(props) {
        library.add(fas, far, fab)
        super(props);
        this.state = {
            profileTrigger: props.profileTrigger
        }
    }
    render() {
        return <a href={this.state.profileTrigger.search_url}>
            <div className='searchTriggerCard'>
                <img className='imageSearchTrigger' src={this.state.profileTrigger.thumbnail} alt='Search thumbnail'></img>
                <div className='tagsContainer'>{this.state.profileTrigger.traits.map(trait => <div className='searchTriggerTrait'>{trait.name}</div>)}</div>
            </div>
            <div className='searchProfileTriggerTitle'>{this.state.profileTrigger.name}</div>
        </a>
    }

}

export default SearchProfileTrigger;