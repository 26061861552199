import React from 'react';
import { Col, Row} from "shards-react";
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import './FlatfinderFooter.css';

class FlatfinderFooter extends React.Component {

    constructor(props) {
        library.add(fas, far, fab)
        super(props);
        this.state = {}
    }
    render() {
        return <div className='FlatfinderFooter'>
            <Row>
                <div className='headerLogo'>
                    <img alt="FlatFinder logo" className='flatFinderImageLogoLanding' src={process.env.PUBLIC_URL + '/logo.svg'}/>
                    <div className='flatfinderTypoLanding'>FLATFINDER</div>
                </div>
            </Row>
            <Row className='footerLinks'>
                <Col xs={12}><a href="/profiles"><b>Profiles</b></a></Col>
                <Col xs={12}><a href="/neighbourhoods"><b>Neighbourhoods</b></a></Col>
                <Col xs={12}><a href="/search"><b>Search</b></a></Col>
            </Row>
            <Row className='subFooterLinks'>
                    <Col xs={12} md={6}>
                        <Row>
                    <Col xs={4}><a href="/cookies-policy">Cookie policy</a></Col>
                    <Col xs={4}><a href="/privacy">Privacy</a></Col>
                    <Col xs={4}><a href="/tos">Terms of use</a></Col>
                    </Row>
                    </Col>
            </Row>
            <Row className='copyright'>© 2021 Inspicio Group Limited. All rights reserved.</Row>
        </div>
    }

}

export default FlatfinderFooter;