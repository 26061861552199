import React from 'react';
import { Col, Row} from "shards-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'

import './PropertyPreview.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

class PropertyPreview extends React.Component {


    constructor(props) {
        library.add(fas, far, fab)
        super(props);
        this.state = {
            property_data: props.property_data,
            selectedTraits: props.selectedTraits
        }
    }


renderMatch(trait){
    if(this.state.property_data.matched_traits.includes(trait.uuid)) {
        return <Col key={'match'+trait.uuid+this.state.property_data.uuid} xs={6}><div className='matchEntry'>
            <FontAwesomeIcon className='hasMatched' icon={faCheckCircle} /> {trait.name}
        </div></Col>
    }
    return <Col xs={6} key={'unmatch'+trait.uuid+this.state.property_data.uuid}><div className='matchEntry'>
        <FontAwesomeIcon className='hasNotMatched' icon={faTimesCircle} /> <span className='strikethrough'>{trait.name}</span>
    </div></Col>
}

renderTransport(transport, i) {
    return <div key={'transport-'+this.state.property_data.uuid+i}>
    {transport.types.map((transport_type) => <img key={'image-'+transport.name+transport_type+this.state.property_data.uuid} alt="transport logo" className='transportLogo' src={process.env.PUBLIC_URL+'/'+transport_type+".png"} />)} {Math.round(transport.distance * 10) / 10} miles {transport.name}
    </div>
}

renderImages(images) {
    return <Carousel showThumbs={false} dynamicHeight={false} showStatus={false}>
        {images.map((image, i) => {return <div key={'img-prop'+i+this.state.property_data.uuid}><img alt={"Property image "+i} className='propertyImage' src={image}/></div>})}
  </Carousel>
}

renderMatchLabel() {
    if(this.state.property_data.score === undefined) {
        return null
    }
    return <div className='matchLabel'>{Math.round(this.state.property_data.score)}% match</div>
}
render() {
    return <div className='wrapperProperty' key={'preview'+this.state.property_data.uuid}>
        <a target="_blank" rel="noreferrer" href={"/properties/"+this.state.property_data.uuid}>
        <Row className='propertyPreviewHover'>
            <Col md={6} sm={6} xs={12}>
               {this.renderImages(this.state.property_data.images)}
            </Col>

            <Col className='propertyInfos' md={6} sm={6} xs={12}>
                <div className='title'>{this.state.property_data.title}</div>
                <Row><Col xs={9}>
                    <div className='price'>£{this.state.property_data.price} pcm to rent</div>
                    </Col>
                    <Col xs={3}>
                        {this.renderMatchLabel()}
                    </Col>
                    </Row>
                <div className='address'>{this.state.property_data.address}</div>
                <div className='matchContainer'>
                    <Row>
                        {this.state.selectedTraits && this.state.selectedTraits.map((trait) => {return this.renderMatch(trait)})}
                    </Row>
                </div>
                <div className='transportContainer'>
                    <div className='title'>Transports</div>
                    {this.state.property_data.transports.map((transport, i) => {return this.renderTransport(transport, i)})}
                </div>
            </Col>
        </Row>
        </a>
    </div>
}

}

export default PropertyPreview;