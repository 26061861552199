import React from 'react';
import { Col, Row, InputGroup, FormSelect} from "shards-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import Autosuggest from 'react-autosuggest';
import PropertyPreview from "./PropertyPreview";

//import './SearchPage.css';
import './PropertyPreview.css';
import './SearchFilters.css';

import configData from './config.json';
import { ToastContainer, toast } from 'react-toastify';
import Loader from "react-loader-spinner";
import 'react-toastify/dist/ReactToastify.css';

class SearchFilters extends React.Component {

    constructor(props) {
        library.add(fas, far, fab)
        super(props);
        this.state = {
            location: {},
            price: null,
            loading: true,
            loadedTraits: [],
            loadedLocations: [],
            bedrooms: 'studio',
            has_changed: false,
            firstPageLoad: true,
            searchError: '',
            suggestions: [],
            suggestionsLocation: [],
            traitSearchValue: '',
            locationSearchValue: '',
            properties: [],
            propertiesTotal: 0,
            randomProperties: [],
            loadedProfile: null,
            profileId: props.profileId || null,
            traits: []
        };
    }
    componentDidMount() {
        if(this.state.profileId) {
            fetch(configData.api_url+'/profiles/'+this.state.profileId)
            .then(response => response.json())
            .then(result => {
                this.setState({traits: result.traits, loadedProfile: result})
                this.launchSearch()
            })
            .catch(e => {
                console.log(e);
            });
        }

        fetch(configData.api_url+'/traits')
            .then(response => response.json())
            .then(result => {
                this.setState({loadedTraits: result.traits})
            })
            .catch(e => {
                console.log(e);
            });
    
        fetch(configData.api_url+'/neighbourhoods')
        .then(response => response.json())
        .then(result => {
            this.setState({loadedLocations: result})
        })
        .catch(e => {
            console.log(e);
        });

        if(!this.state.randomProperties.length && !this.state.profileId) {
            fetch(configData.api_url+'/properties/random')
            .then(response => response.json())
            .then(result => {
                this.setState({randomProperties: result, loading: false})
            })
            .catch(e => {
                console.log(e);
            });
        }

      }

    getTraitValue(trait){
        return trait.name
    }

    renderTraitSelect(trait) {
        return <div key={'trait-render'+trait.uuid} className='traitResult'>{trait.name}</div>
    }
    renderTraitSelection(trait, index) {

        return <div key={'trait-selection'+index} className='selectedTrait'>
            <b className='traitTitle'>{trait.name}</b><div onClick={() => {this.removeTrait(index)}} className='removeTraitFlag'><FontAwesomeIcon icon={faTimes}></FontAwesomeIcon> Remove</div>
            <div className='traitButtonContainer'>
                {this.renderTraitButton('Must have', trait, 'must_have', index)}
                {this.renderTraitButton('Nice to have', trait, 'nice_to_have', index)}
                {this.renderTraitButton('Exclude', trait, 'exclude', index)}
            </div>
        </div>
    }

    removeTrait(index)
    {
        let traits = this.state.traits
        traits.splice(index, 1)
        this.setState({traits: traits, has_changed: true})
    }
    changeTraitTo(trigger, index)
    {
        let traits = this.state.traits

        let currentTrait = traits[index]
        currentTrait.importance = trigger
        traits[index] = currentTrait

        this.setState({traits: traits, has_changed: true})
    }

    handlePriceChange(e) {
        this.setState({
          price: e.target.value,
          has_changed: true
        })
    }
    handleBedroomChange(e) {
        this.setState({
          bedrooms: e.target.value,
          has_changed: true
        })
    }

    canSearch() {
        if(this.state.loading) {
            return false
        }

        if(!this.state.location.name) {
            return false
        }

        return true
    }

    renderTraitButton(text, trait, trigger, index) {
        let className = 'traitButton inactive'
        if (trait.importance === trigger) {
            className = 'traitButton active'
        }
        return <button onClick={() => this.changeTraitTo(trigger, index)} className={className}>{text}</button>
    }

    renderSearchButton() {
        if(this.state.has_changed && this.state.properties.length) {
            return <button disabled={!this.canSearch()} onClick={this.launchSearch.bind(this)} className='viewMatchButton'>Update matches</button>
        }
        return <button disabled={!this.canSearch()} onClick={this.launchSearch.bind(this)} className='viewMatchButton'>View matches</button>
    }

    renderProperties() {

        if(this.state.loading){
                return <Loader type="ThreeDots" height={100} width={100} color="#25283D" />
        }

        if(this.state.firstPageLoad && this.state.randomProperties.length) {
            return <div>
            {this.state.randomProperties.map((property_data, i) => { 
                return <div key={'random-prop'+i} className='propertyContainer'>
                    <PropertyPreview property_data={property_data}/>
                </div>
            })}
            </div>
        }

        if(!this.state.properties.length) {
            return <div>
                <i>{this.state.searchError}</i>
            </div>
        }

        return <div>
            {this.state.properties.map((property_data, i) => { 
                return <div key={'property-render-'+i}className='propertyContainer'>
                    <PropertyPreview property_data={property_data} selectedTraits={this.state.traits}/>
                </div>
            })}
        </div>
    }
    renderStringSearch() {
        if (!this.state.properties.length) {
            return <div><div className='searchString'>Here's some interesting properties while you tailor your search</div></div>
        }
        
        let searchStringBegin = 'Studio'

        if (this.state.bedrooms !== 'studio'){
            searchStringBegin = this.state.bedrooms + ' bedrooms'
        }
        if (!this.state.price && !this.state.location.name && this.state.loadedProfile) {
            return <div><div className='searchString'>{this.state.loadedProfile.name} anywhere without budget restrictions</div></div>
        }

        let searchAsString = searchStringBegin + ' flats at £'+this.state.price

        if(this.state.location.name) {
            searchAsString += ' near '+this.state.location.name
        } else {
            searchAsString += ' anywhere in London'
        }
        return <div>
            <div class='matchNumberString'>{this.state.propertiesTotal} matches</div><div class='searchString'>{searchAsString}</div>
        </div>
    }

    generateOptionsPrice() {
        const startAt = 1200
        const finishAt = 8000
        const increment = 150
        
        let options = []
        for (let index = startAt; index < finishAt; index += increment) {
            options.push({
                value: index,
                name: '£'+index+' pcm'
            })
        }
        options.push({
            value: '',
            name: 'Money is no object'
        })

        return options.map(option => <option key={'price-option'+option.name} value={option.value}>{option.name}</option>)
    }

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
          suggestions: this.getSuggestions(value)
        });
      };
      
      getSuggestions = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
      
        return inputLength === 0 ? [] : this.state.loadedTraits.filter(trait =>
          trait.name.toLowerCase().slice(0, inputLength) === inputValue
        );
      };
      onSuggestionsClearRequested = () => {
        this.setState({
          suggestions: [],
        });
      };

      onSuggestionsFetchRequestedLocation = ({ value }) => {
        this.setState({
          suggestionsLocation: this.getSuggestionsLocation(value)
        });
      };
      
      getSuggestionsLocation = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
      
        return inputLength === 0 ? [] : this.state.loadedLocations.filter(location =>
            location.name.toLowerCase().slice(0, inputLength) === inputValue
        );
      };
      onSuggestionsClearRequestedLocation = () => {
        this.setState({
          suggestionsLocation: [],
        });
      };

      handleSelectLocation(event, values) {
        
        if(values) {
          this.setState({
            location: this.state.loadedLocations.filter(loadedLocation => loadedLocation.id === values.suggestion.id)[0],
          })
        }
  
      }

      handleSelectTrait(event, values) {
        
        if(values) {
        let traits = this.state.traits
        console.log(values)
        let trait = this.state.loadedTraits.filter(loadedTrait => loadedTrait.uuid === values.suggestion.uuid)[0]
        trait.importance = 'nice_to_have'
        traits.push(trait)
          this.setState({
            traits: traits,
            traitSearchValue: ''
          })
        }
  
      }

      launchSearch() {
        this.setState({loading: true, firstPageLoad: false, searchError: '', properties: [], propertiesTotal: 0})
        fetch(configData.api_url+'/properties/search', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            neighbourhoods: [this.state.location.identifier],
            budget: this.state.price ? parseFloat(this.state.price) : null,
            min_bedrooms: this.state.bedrooms === 'studio' ? 0 : parseInt(this.state.bedrooms),
            traits: this.state.traits
          })
        })
        .then(response => response.json())
        .then(result => {
            this.setState({loading: false});
            if(result.error) {
              toast.error(result.error);
              return;
            }
            if(!result.results.length) {
              toast.info("No results found, try broadening your search")
              this.setState({searchError: 'No results found, try broadening your search'})
              return;
            }
            this.setState({
              properties: result.results,
                propertiesTotal: result.total,
              randomProperties: [],
              loading: false,
              has_changed: false
            })
        })
        .catch(e => {
            this.setState({loading: false, searchError: 'Server error, please try again'});

            toast.error("Failed to run a search");
        });
      }

      changeTraitSearchInput(event, { newValue }) {
          this.setState({
                traitSearchValue: newValue
          })
      }

      changeLocationSearchInput(event, { newValue }) {
        this.setState({
              locationSearchValue: newValue
        })
    }
    renderSideBar() {
        return <div className='filtersContainer'>
        <h3 className='filterTitle'>Search filters</h3>
        <Row>
            <Col sm="12" className='inputMargin'>
                <label htmlFor="location">Neighborood</label>
                <div className='searchFilterDetails'><a href="/neighbourhoods" target="_blank" rel="noreferrer">Explore our available neighborhoods <FontAwesomeIcon icon={faExternalLinkAlt}></FontAwesomeIcon></a></div>
                <Autosuggest
                    className="flatFinderAutoComplete"
                    suggestions={this.state.suggestionsLocation}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequestedLocation.bind(this)}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequestedLocation}
                    getSuggestionValue={this.getTraitValue}
                    onSuggestionSelected={this.handleSelectLocation.bind(this)}
                    renderSuggestion={this.renderTraitSelect}
                    inputProps={{
                        placeholder: 'Search from curated neighborhoods, eg : "Canary Wharf"',
                        value: this.state.locationSearchValue,
                        onChange: this.changeLocationSearchInput.bind(this)
                    }}/>
            </Col>
        </Row>
        <Row>
            <Col sm="6" className='inputMargin'>
                <label htmlFor="budget">Max price</label>
                <InputGroup>
                    <FormSelect onChange={this.handlePriceChange.bind(this)} className='flatFinderInput' id="budget">
                            {this.generateOptionsPrice()}
                    </FormSelect>
                </InputGroup>
            </Col>
            <Col sm="6" className='inputMargin'>
                <label htmlFor="min_rooms">Min rooms</label>
                <InputGroup>
                    <FormSelect onChange={this.handleBedroomChange.bind(this)} className='flatFinderInput' id="min_rooms">
                            <option value='studio'>Studio</option>
                            <option value='1'>1</option>
                            <option value='2'>2</option>
                            <option value='3'>3</option>
                            <option value='4'>4+</option>
                    </FormSelect>
                </InputGroup>
            </Col>
        </Row>
        <Row>
            <Col sm="12" className='inputMargin'>
                <label htmlFor="location">Amenities</label>
                <Autosuggest
                    className="flatFinderAutoComplete"
                    suggestions={this.state.suggestions}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested.bind(this)}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    getSuggestionValue={this.getTraitValue}
                    onSuggestionSelected={this.handleSelectTrait.bind(this)}
                    renderSuggestion={this.renderTraitSelect}
                    inputProps={{
                        placeholder: 'Search for a trait: Eg "Parking, High Rise.."',
                        value: this.state.traitSearchValue,
                        onChange: this.changeTraitSearchInput.bind(this)
                    }}
                                    />
            </Col>
        </Row>
        <Row>
            <Col sm='12'>
                {this.state.traits.map((trait, i) => {return this.renderTraitSelection(trait, i)})}
            </Col>
        </Row>
        <div className='searchButtonContainer'>
                {this.renderSearchButton()}
        </div>
    </div>
    }
    render() {
        return <div className='searchPage'>

            <Row>
                <ToastContainer/>
                <Col sm={4}>
                    {this.renderSideBar()}
                </Col>
                <Col className='propertiesContainer' sm={8}>
                    <div className='searchStringContainer'>{this.renderStringSearch()}</div>
                    {this.renderProperties()}
                </Col>
            </Row>

        </div>
    }
}

export default SearchFilters;