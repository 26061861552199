import React from 'react';
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import { Col, Row, Button} from "shards-react";
import configData from './config.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './PropertyPage.css';

class PropertyPage extends React.Component {

    constructor(props) {
        library.add(fas, far, fab)
        super(props);
        this.state = {
            propertyId: props.propertyId,
            property_data: {},
            user: JSON.parse(localStorage.getItem('user') || '{}'),
            match: props.match || {traits: []}
        }
        this.saveFlat = this.saveFlat.bind(this)
        this.removeFlat = this.removeFlat.bind(this)
    }

    componentDidMount() {
        this.loadFlat()
    }

    loadFlat() {
        let headers = {
            'Content-Type': 'application/json'
        }
        if(this.state.user.token) {
            headers['Authorization'] = 'Bearer '+this.state.user.token
        }

        fetch(configData.api_url+'/properties/'+this.state.propertyId, {
            method: 'GET',
            headers: headers
        })
        .then(response => response.json())
        .then(result => {
            console.log(result)
            this.setState({property_data: result})
        })
        .catch(e => {
            console.log(e);
        });
    }

    saveFlat() {
        this.sendLikeRequest(true)
    }

    removeFlat() {
        this.sendLikeRequest(false)
    }

    sendLikeRequest(likeOrNot) {
        fetch(configData.api_url+'/properties/'+this.state.propertyId+'/save', {
            method: likeOrNot ? 'PUT' : 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer '+this.state.user.token
            }
          })
          .then(response => {
              response.json()
              this.loadFlat()
            }).catch(error => console.log(error))
    }

    renderSaveButton() {
        console.log(this.state.user)
        if(this.state.property_data.saved) {
            return <Button className='propertyButton' onClick={this.removeFlat}>Remove from saved</Button>
        }
        //Render select for status
        if(!this.state.user.token) {
            return <Button className='propertyButton' onClick={null}>Sign in to save</Button>
        }

        return <Button className='propertyButton' onClick={this.saveFlat}>Save</Button>
    }

    renderTrait(trait) {
        return <Col xs={6}><div className='matchEntry'>
        <FontAwesomeIcon className='hasMatched' icon={faCheckCircle} /> {trait.name}
    </div></Col>
    }
    renderMatch(match){
        if(match.match) {
            return <Col xs={6}><div className='matchEntry'>
                <FontAwesomeIcon className='hasMatched' icon={faCheckCircle} /> {match.name}
            </div></Col>
        }
        return <Col xs={6}><div className='matchEntry'>
            <FontAwesomeIcon className='hasNotMatched' icon={faTimesCircle} /> <span className='strikethrough'>{match.name}</span>
        </div></Col>
    }
    
    renderTransport(transport) {
        return <div>
            {transport.types.map((transport_type) => <img key={'image-'+transport.name+transport_type} alt="transport logo" className='transportLogo' src={process.env.PUBLIC_URL+'/'+transport_type+".png"} />)} {Math.round(transport.distance * 10) / 10} miles {transport.name}
        </div>
    }
    
    renderImages(images) {
        return <Carousel showThumbs={false} showStatus={false}>
            {images.map((image, i) => {return <div key={"image-"+i}><img alt={"Property image "+i} className='propertyImage' src={image}/></div>})}
      </Carousel>
    }
    
    renderMatchLabel() {
        if(!this.state.match.score) {
            return null
        }
        return <div className='matchLabel'>{this.state.match.score}% match</div>
    }

    renderTransportContainer() {
        if(!this.state.property_data.transports.length) {
            return null
        }
        return <div className='transportContainer'>
            <div className='title'>Transports</div>
            {this.state.property_data.transports.map((transport, i) => {return this.renderTransport(transport)})}
        </div>
    }
    render() {
        if (!this.state.property_data.uuid) {
            return <div>Loading..</div>
        }

        return <div className='wrapperProperty'>
            <Row>
                <Col md={6} sm={6} xs={12}>
                   {this.renderImages(this.state.property_data.images)}
                </Col>
    
                <Col className='propertyInfos' md={6} sm={6} xs={12}>
                    <div className='title'>{this.state.property_data.title}</div>
                    <Row><Col xs={9}>
                        <div className='price'>£{this.state.property_data.price} pcm to rent</div>
                        </Col>
                        <Col xs={3}>
                            {this.renderMatchLabel()}
                        </Col>
                        </Row>
                    <div className='address'>{this.state.property_data.address}</div>
                    <div className='matchContainer'>
                        <div className='title'>Traits</div>
                        <Row>
                            {this.state.property_data.traits.map((trait, i) => {return this.renderTrait(trait)})}
                        </Row>
                    </div>
                    <div className='matchContainer'>
                        <Row>
                            {this.state.match.traits.map((oneMatch, i) => {return this.renderMatch(oneMatch)})}
                        </Row>
                    </div>
                    {this.renderTransportContainer()}

                    <div className='description'>
                        <div className='title'>Description</div>
                        {this.state.property_data.description.split('\n').map(text => <p>{text}</p>)}
                    </div>
                    <div className='buttonControls'>
                        {this.renderSaveButton()}
                        <Button className='propertyButton' href={this.state.property_data.url}>See original advert</Button>
                    </div>
                </Col>
            </Row>
        </div>
    }

}

export default PropertyPage;