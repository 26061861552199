import React from 'react';
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Row} from "shards-react";
import { faHeart } from '@fortawesome/free-solid-svg-icons'
import './SearchProfile.css';

class SearchProfile extends React.Component {

    constructor(props) {
        library.add(fas, far, fab)
        super(props);
        this.state = {
            profile: props.profile
        }
    }
    render() {
        return <div className='searchProfileContainer'>
            <div className='searchProfileTitle'>{this.state.profile.profile_name}</div>
            <div className='likesSearchProfile'><FontAwesomeIcon className='likeHeart' icon={faHeart} /> {this.state.profile.likes} liked this</div>
            <Row>
                <Col xs={6}>
                    <div className='searchProfileTitleSection'>Must have</div>
                    {this.state.profile.must_haves.map(must_have => <div className='marginBadges'><div className='mustHave'>{must_have.name}</div></div>)}
                </Col>
                <Col xs={6}>
                    <div className='searchProfileTitleSection'>Nice to have</div>
                    {this.state.profile.nice_to_haves.map(nice_to_have => <div className='marginBadges'><div className='niceToHave'>{nice_to_have.name}</div></div>)}
                </Col>
            </Row>
        </div>
    }

}

export default SearchProfile;