import React from 'react';
import { Col, Row} from "shards-react";
import { faCheckCircle, fas, faHeart } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SearchProfile from './SearchProfile';
import NeighbourhoodThumb from './NeighbourhoodThumb';
import SearchProfileTrigger from './SearchProfileTrigger';
import LandingCallToAction from './LandingCallToAction';
import PropertyThumb from './PropertyThumb';
import './LandingPage.css';
import configData from './config.json';

class LandingPage extends React.Component {

    constructor(props) {
        library.add(fas, far, fab)
        super(props);
        this.state = {
            latest_listings: [],
            neighbourhoods: []
        }
    }
    componentDidMount() {
        fetch(configData.api_url+'/properties/latest')
            .then(response => response.json())
            .then(result => {
                this.setState({latest_listings: result})
            })
            .catch(e => {
                console.log(e);
            });
        fetch(configData.api_url+'/neighbourhoods')
            .then(response => response.json())
            .then(result => {
                console.log(result)
                this.setState({neighbourhoods: result})
            })
            .catch(e => {
                console.log(e);
            });
    }

    renderStockImages() {
        return <Row className='stockImagesContainer'>
            <Col className='noPadding' xs={4}>
                <img alt='stock 1' className='stockImage1' src={process.env.PUBLIC_URL + '/landing-stock-1.png'}/>
                <img alt='stock 2' className='stockImage2' src={process.env.PUBLIC_URL + '/landing-stock-2.png'}/>
            </Col>
            <Col className='noPadding' xs={8}>
                <img alt='stock 3' className='stockImage3' src={process.env.PUBLIC_URL + '/landing-stock-3.png'} />
            </Col>
        </Row>
    }


    renderHeader() {
        return <div className='headerLanding'>
                <div className='verticalAlignLanding'>
                <div className='headerLogo'>
                    <img alt="FlatFinder logo" className='flatFinderImageLogoLanding' src={process.env.PUBLIC_URL + '/logo.svg'}/>
                    <div className='flatfinderTypoLanding'>FLATFINDER</div>
                </div>
                <div className='landingHeaderTitle'>
                    The best place to live
                </div>
                <div className='landingHeaderSubTitle'>
                    We think about property listings differently in order to find the right flat for you
                </div>
                </div>
        </div>
    }

    renderTopHeader() {
            return <Row className='marginFix'>
                <Col sm={12} md={6}>
                    {this.renderHeader()}
                </Col>
                <Col sm={12} md={6}>
                    {this.renderStockImages()}
                </Col>
            </Row>
    }

    renderFakePropertyCard() {
        return <div className='fakeProperty'>
            <Row>
                <Col xs={6}>
                    <img className='fakePropertyImage' alt='property' src={process.env.PUBLIC_URL + '/landing-stock-4.png'}/>
                    <div className='likesSearchProfile'><FontAwesomeIcon className='likeHeart' icon={faHeart} /> 2 liked this</div>
                    <div className='fakeTitle'>2 bedroom flat to rent on Chiswick High Road</div>
                    <div className='fakePrice'><b className='boldedPrice'>£1850</b> / month</div>
                </Col>
                <Col xs={6}>
                    <div className='traitFake'>
                        <Row className='traitListed'>
                            <Col xs={2}>
                                <FontAwesomeIcon className='green' icon={faCheckCircle}/>
                            </Col>
                            <Col xs={10}>
                                <div className='BigtraitTitle'>Natural light</div>
                                <div className='traitSubtitle'>This place has great light</div>
                            </Col>
                        </Row>
                        <Row className='traitListed'>
                            <Col xs={2}>
                                <FontAwesomeIcon className='green' icon={faCheckCircle}/>
                            </Col>
                            <Col xs={10}>
                                <div className='BigtraitTitle'>Large windows</div>
                                <div className='traitSubtitle'>There are floor-to-ceiling windows in this flat</div>
                            </Col>
                        </Row>
                        <Row className='traitListed'>
                            <Col xs={2}>
                                <FontAwesomeIcon className='green' icon={faCheckCircle}/>
                            </Col>
                            <Col xs={10}>
                                <div className='BigtraitTitle'>Wood floors</div>
                                <div className='traitSubtitle'>This place has wood floors</div>
                            </Col>
                        </Row>
                        <Row className='traitListed'>
                            <Col xs={2}>
                                <FontAwesomeIcon className='green' icon={faCheckCircle}/>
                            </Col>
                            <Col xs={10}>
                                <div className='BigtraitTitle'>Garden</div>
                                <div className='traitSubtitle'>This place has a garden</div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </div>
    }

    renderHowItWork() {
        return <Row className='howItWorks'>
                <Col xs={12}>
                    <div class='LandingsectionTitle'>How it works</div>
                </Col>
            <Col sm={12} md={6} className='verticalPadFixLanding'>
                <SearchProfile profile={{
                    profile_name: "Olivia's dream flat",
                    likes: 174,
                    control_disabled: true,
                    must_haves: [
                        {name: 'Natural Light'},
                        {name: 'Large windows'},
                        {name: 'Wood floor'}
                    ],
                    nice_to_haves: [
                        {name: 'Bathtub'},
                        {name: 'Garden'}
                    ]
                }}/>
                <div className='landingSubtext'>
                    Use our library of traits and styles to create a profile of your perfect place to live
                </div>
            </Col>
            <Col sm={12} md={6}>
                {this.renderFakePropertyCard()}
                <div className='landingSubtext'>
                   Subscribe and get notified the moment new property listings match your profile
                </div>
            </Col>
        </Row>
    }

    renderExploreProfile() {
        let profileTriggers = [
            {
                'search_url': '/search?profileId=9494b288-d22a-4c36-96b4-2edad8983bd8',
                'traits': [
                    {
                        'name': 'Wood floor'
                    },
                    {
                        'name': 'Natural light'
                    },
                    {
                        'name': 'Large windows'
                    }
                ],
                'name': 'Rooms with a view',
                'thumbnail': process.env.PUBLIC_URL + '/profile-1.png'
            },
            {
                'search_url': '/search?profileId=54f33f9e-4516-48fb-96f1-2e2ec25bee61',
                'traits': [
                    {
                        'name': 'Brick'
                    },
                    {
                        'name': 'Open plan'
                    },
                    {
                        'name': 'Comfy'
                    }
                ],
                'name': 'Exposed brick',
                'thumbnail': process.env.PUBLIC_URL + '/profile-2.png'
            },
            {
                'search_url': '/search?profileId=c5e2dfc3-5660-4089-9eea-0a60f50edb27',
                'traits': [
                    {
                        'name': 'Garden'
                    },
                    {
                        'name': 'Plants'
                    },
                    {
                        'name': 'Natural light'
                    }
                ],
                'name': 'Garden flats',
                'thumbnail': process.env.PUBLIC_URL + '/profile-3.png'
            }
        ];
        return <Row className='exploreProfile'>
            <Col xs={12}>
                <div class='LandingsectionTitle'>Explore profiles</div>
                <div className='LandingSectionSubText'>A new way to look for places to live</div>
            </Col>
            <Col xs={12} className='profileTriggerContainer'>
                <Row>
                {profileTriggers.map(profileTrigger => <Col xs={4}><SearchProfileTrigger profileTrigger={profileTrigger} /></Col>)}
                </Row>
            </Col>

        </Row>
    }

    renderNeighbourhoods() {
        return <Row className='neighbourhoodsMainContainer'>
            <Col xs={12}><div className='LandingsectionTitle'>Explore London neighbourhoods</div></Col>
            {this.state.neighbourhoods.map(neighbourhood => <Col className='neighbourhoodCont' xs={12} md={6} lg={3}><NeighbourhoodThumb neighbourhood={neighbourhood} /></Col>)}
        </Row>
    }


    render() {
        return <div className='landingMargins'>
            <div>{this.renderTopHeader()}</div>
            <div>{this.renderHowItWork()}</div>
            <div>{this.renderExploreProfile()}</div>
            <div>{this.renderNeighbourhoods()}</div>
            <div>
                <Row>
                    <div className='newestListings'>
                        <Col xs={12}><div className='LandingsectionTitle'>Newest listings</div></Col>
                        <Row>{this.state.latest_listings.map(listing => <Col xs={3} sm={3} md={3} lg={3}><PropertyThumb property_data={listing} /></Col>)}</Row>
                    </div>
                </Row>
            </div>
            <div><LandingCallToAction /></div>
        </div>
    }

}

export default LandingPage;