import React from 'react';
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import './NeighbourhoodsList.css';
import { Col, Row} from "shards-react";
import NeighbourhoodThumb from './NeighbourhoodThumb';
import LandingCallToAction from './LandingCallToAction';
import PropertyThumb from './PropertyThumb';
import configData from './config.json';

class NeighbourhoodsList extends React.Component {

    constructor(props) {
        library.add(fas, far, fab)
        super(props);
        this.state = {
            neighbourhoods: [],
            latest_listings: []
        }
    }
    componentDidMount() {
        fetch(configData.api_url+'/properties/latest')
            .then(response => response.json())
            .then(result => {
                this.setState({latest_listings: result})
            })
            .catch(e => {
                console.log(e);
            });

        fetch(configData.api_url+'/neighbourhoods')
            .then(response => response.json())
            .then(result => {
                console.log(result)
                this.setState({neighbourhoods: result})
            })
            .catch(e => {
                console.log(e);
            });
    }

    renderNeighbourhoods() {
        return <Row className='neighbourhoodsMainContainer'>
            {this.state.neighbourhoods.map(neighbourhood => <Col key={'col-'+neighbourhood.uuid} className='neighbourhoodCont' xs={12} md={6} lg={3}><NeighbourhoodThumb neighbourhood={neighbourhood} /></Col>)}
        </Row>
    }
    render() {
        return <div className='landingMargins'>
            <div>
                <Row>
            <Col xs={12}><div className='LandingsectionTitle'>London neighbourhoods</div></Col>
            <Col xs={12}><div className='neighbourhoodsListSubtext'>Discover listings in top-rated London neighbourhoods</div></Col>
            </Row>
            </div>
            <div>{this.renderNeighbourhoods()}</div>
            <div>
                <Row>
                    <div className='newestListings'>
                        <Col xs={12}><div className='LandingsectionTitle'>Newest listings</div></Col>
                        <Row>{this.state.latest_listings.map(listing => <Col key={'col-prop'+listing.uuid} xs={3} sm={3} md={3} lg={3}><PropertyThumb property_data={listing} /></Col>)}</Row>
                    </div>
                </Row>
            </div>
            <div><LandingCallToAction /></div>
        </div>
    }

}

export default NeighbourhoodsList;