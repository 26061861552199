import React from 'react';
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import './NeighbourhoodThumb.css';
import { Col, Row} from "shards-react";

class NeighbourhoodThumb extends React.Component {

    constructor(props) {
        library.add(fas, far, fab)
        super(props);
        this.state = {
            neighbourhood: props.neighbourhood
        }
    }
    render() {
        return <div className='neighbourhoodContainer' key={this.state.neighbourhood.uuid}>
            <a href={'/neighbourhoods/'+this.state.neighbourhood.identifier}>
                <Row>
                    <Col xs={3}>
                        <img className='neighbourhoodImage' src={this.state.neighbourhood.images[0]} alt='Neighbourhood' />
                    </Col>
                    <Col xs={9}>
                        <div className='neighbourhoodTitle'>{this.state.neighbourhood.name}</div>
                        <div className='neighbourhoodSubTitle'>{this.state.neighbourhood.cardinal_line}</div>
                    </Col>
                </Row>
            </a>
        </div>
    }

}

export default NeighbourhoodThumb;