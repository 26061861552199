import React from 'react';
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'
import './PropertyThumb.css';

class PropertyThumb extends React.Component {

    constructor(props) {
        library.add(fas, far, fab)
        super(props);
        this.state = {
            property_data: props.property_data
        }
    }
    render() {
        return <div className="propertyThumbHover" key={this.state.property_data.uuid}>
            <a href={"/properties/"+this.state.property_data.uuid}><div className='propertyThumbImgContainer'>
                <img className='propertyThumbImg' src={this.state.property_data.images[0]} alt='Property' />
            </div>
            <div className='likesPropertyThumb'><FontAwesomeIcon className='likeHeart' icon={faHeart} /> {this.state.property_data.likes || 0} liked this</div>
            <div className='titlePropertyThumb'>{this.state.property_data.title}</div>
            <div className='titlePropertyThumb'>{this.state.property_data.address}</div>
            <div className='pricePropertyThumbContainer'><div className='pricePropertyThumb'>£{this.state.property_data.price}</div> / month</div></a>
        </div>
    }

}

export default PropertyThumb;