import React from 'react';
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Collapse,
    Nav,
    NavLink,
    NavItem,
    Button
  } from "shards-react";
import './FlatFinderNav.css';
import configData from './config.json';

class FlatFinderNav extends React.Component {

    constructor(props) {
        library.add(fas, far, fab)
        super(props);
        this.state = {
            dropdownOpen: false,
            accountDropdownOpen: false,
            navbarOpen: false
        }
        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.toggleAccountDropdown = this.toggleAccountDropdown.bind(this);
        this.user = JSON.parse(localStorage.getItem('user') || '{}')
        this.attemptLogin()
    }
    toggleDropdown() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        })
    }

    toggleAccountDropdown() {
        this.setState({
          accountDropdownOpen: !this.state.accountDropdownOpen
        })
    }

    toggleNavbar() {
        this.setState({
            navbarOpen: !this.state.navbarOpen
        })
    }

attemptLogin() {
  if(this.props.params.get('code')) {
    fetch(configData.api_url+'/auth/callback?'+this.props.params.toString())
    .then(response => response.json())
    .then(result => {
        localStorage.setItem('user', JSON.stringify(result))

        window.location = '/'
    })
    .catch(e => {
        console.log(e);
    });
  }
}

logout() {
  localStorage.setItem('user', '{}')
  window.location = '/'
}

renderLoginButton() {

  if(this.user.token) {
    return <Dropdown
        open={this.state.accountDropdownOpen}
        toggle={this.toggleAccountDropdown}
      >
          <DropdownToggle nav caret>
            {this.user.email}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => this.logout()}>Logout</DropdownItem>
            <DropdownItem href="/saved">My saved flats</DropdownItem>
          </DropdownMenu>
      </Dropdown>
  }

  return <NavItem className="nav-call-to-action">
    <Button href={configData.api_url+'/auth/redirect/google'} className='navButton'>Sign in</Button>
  </NavItem>
}
render() {
    return <Navbar type="light" theme="white" expand="md" sticky={'true'}>
    <NavbarBrand href="/">
                <img alt="FlatFinder logo" className='flatFinderImageLogo' src={process.env.PUBLIC_URL + '/logo512.png'}/>
                <div className='flatfinderTypo'>FLATFINDER</div>
    </NavbarBrand>
    <NavbarToggler onClick={this.toggleNavbar} />

    <Collapse open={this.state.navbarOpen} navbar>
      <Nav navbar className="ml-auto">
        <NavItem>
          <NavLink className='flatfinderNav' href="#" disabled>
            Profiles
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className='flatfinderNav' href="/neighbourhoods">
            Neighbourhoods
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className='flatfinderNav' href="/search">
            Search
          </NavLink>
        </NavItem>
        {this.renderLoginButton()}
      </Nav>
    </Collapse>
  </Navbar>
}

}

export default FlatFinderNav;