import React from 'react';
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import './NeighbourhoodPage.css';
import PropertyThumb from './PropertyThumb';
import { Col, Row} from "shards-react";
import LandingCallToAction from './LandingCallToAction';
import configData from './config.json';

class NeighbourhoodPage extends React.Component {

    constructor(props) {
        library.add(fas, far, fab)
        super(props);
        this.state = {
            neighbourhoodId: props.neighbourhoodId,
            neighbourhood: {},
            listings_in_neighbourhood: [],
            latest_listings: []
        }
    }

    componentDidMount() {
        fetch(configData.api_url+'/properties/latest')
        .then(response => response.json())
        .then(result => {
            this.setState({latest_listings: result})
        })
        .catch(e => {
            console.log(e);
        });
        fetch(configData.api_url+'/neighbourhoods/'+this.state.neighbourhoodId)
            .then(response => response.json())
            .then(result => {
                this.setState({neighbourhood: result})
                fetch(configData.api_url+'/properties/search', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  neighbourhoods: [this.state.neighbourhood.identifier],
                  max_results: 4
                })})
                .then(response => response.json())
                .then(result => {
                    this.setState({listings_in_neighbourhood: result.results})
                })
                .catch(e => {
                    console.log(e);
                })
            })
            .catch(e => {
                console.log(e);
            });
    }

    renderFindAPlace() {
        return <Row><div className='newestListings'>
        <Col xs={12}><div className='NeighbourHoodPageSearchTitle'>Find a place in {this.state.neighbourhood.name}</div></Col>
        <Row>{this.state.listings_in_neighbourhood.map(listing => <Col key={'find-a-place'+listing.uuid} xs={3} sm={3} md={3} lg={3}><PropertyThumb property_data={listing} /></Col>)}</Row>
    </div></Row>
    }

    renderNewListings() {
        return <b>TODO</b>
    }

    renderTransports() {
        return <b>TODO</b>
    }

    render() {

        if (!this.state.neighbourhood.images) {
            return <div>Loading..</div>
        }

        return <div>
            <div className='neighbourHoodPageHeadingImageCont'>
                <img alt="neighborhood" className='neighbourHoodPageHeadingImage' src={this.state.neighbourhood.images[0]} />
            </div>
            <div className='marginNeighbourhoodPage'>
                <div className='neighbourHoodPageTitle'>{this.state.neighbourhood.name}</div>
                <div className='neighbourHoodPageDesc'>{this.state.neighbourhood.description}</div>
                <div className='findAPlace'>{this.renderFindAPlace()}</div>
                <div className='neighbourHoodPageLocation'>

                </div>
                <Row>
                    <div className='newestListings'>
                        <Col xs={12}><div className='LandingsectionTitle'>Newest listings</div></Col>
                        <Row>{this.state.latest_listings.map(listing => <Col key={'newest-'+listing.uuid} xs={3} sm={3} md={3} lg={3}><PropertyThumb property_data={listing} /></Col>)}</Row>
                    </div>
                </Row>
            </div>
            <LandingCallToAction />

        </div>
    }

}

export default NeighbourhoodPage ;