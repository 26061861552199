import React from 'react';
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import { Col, Row, Button} from "shards-react";
import configData from './config.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import PropertyThumb from './PropertyThumb';
import './SavedProperties.css';

class SavedProperties extends React.Component {

    constructor(props) {
        library.add(fas, far, fab)
        super(props);
        this.state = {
            flats: [],
            error: null,
            user: JSON.parse(localStorage.getItem('user') || '{}'),
            match: props.match || {traits: []}
        }
        this.removeFlat = this.removeFlat.bind(this)
    }

    componentDidMount() {
        this.loadSaved()
    }
    loadSaved() {
        fetch(configData.api_url+'/me/saved', {headers: {'Authorization': 'Bearer '+this.state.user.token}})
        .then(response => response.json())
        .then(result => {
            this.setState({flats: result})
        })
        .catch(e => {
            this.setState({'error': 'Failed to load page'})
            console.log(e);
        });
    }

    removeFlat(propertyUuid) {
        fetch(configData.api_url+'/properties/'+propertyUuid+'/save', {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer '+this.state.user.token
            }
          })
          .then(response => {
              response.json()
              this.loadSaved()
            }).catch(error => console.log(error))
    }

    renderOneSaved(listing) {
        if(!listing) {
            return null
        }
        return <Col key={'flatsaved-'+listing.uuid} xs={12} sm={6} md={3} lg={3}>
        <Button className='trashButton' onClick={() => this.removeFlat(listing.uuid)}><FontAwesomeIcon icon={faTimesCircle} /> </Button>
        <PropertyThumb property_data={listing} />
        </Col>
    }
    render() {

        if(this.state.error) {
            return <h3>{this.state.error}</h3>
        }
        if (!this.state.flats) {
            return <div>Loading..</div>
        }

        return <div className='SavedPropertiesmargin'>
            <Row>{this.state.flats.map(listing => this.renderOneSaved(listing))}</Row>
        </div>
    }

}

export default SavedProperties;