import React from 'react';
import { Button, Col, Row} from "shards-react";
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import './LandingCallToAction.css';

class LandingCallToAction extends React.Component {

    constructor(props) {
        library.add(fas, far, fab)
        super(props);
        this.state = {}
    }
    render() {
        return <div className='callToAction'>
            <Row className='callToActionAlign'>
                <Col className='callToContainer' xs={12} sm={5}>
                    <div className='callToActionTitle'>Create your profile</div>
                    <div className='callToActionSubTitle'>Use our library of traits and styles to create a profile of your perfect place to live</div>
                    <Button href="/search" className='callToActionBtn'>Get started</Button>
                </Col>
                <Col xs={12} sm={7}>
                    <img src={process.env.PUBLIC_URL + '/landing-call-to-action.png'} alt="Create your profile" className='callToActionImg' />
                </Col>
            </Row>
        </div>
    }

}

export default LandingCallToAction;